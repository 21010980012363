import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../store/AuthContext";

const Navdata = () => {
  const history = useHistory();
  //state data
  const { user } = useContext(AuthContext);
  const [isDashboard, setIsDashboard] = useState(false);
  const [isSettingCompany, setIsSettingCompany] = useState(false);
  const [isServicesCompany, setIsServicesCompany] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  //Dropdown Toggle
  useEffect(() => {
    if (user?.roles) {
      user?.roles?.forEach((element) => {
        element?.includes("ADMIN") && setIsAdmin(true);
      });
    }
  }, [user]);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  // Pages
  const [isProfile, setIsProfile] = useState(false);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");

    if (iscurrentState === "Company") {
      history.push("/company");
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isSettingCompany,
    isServicesCompany,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },

    {
      id: "company",
      label: "Company",
      icon: "ri-building-4-line",
      link: "/company",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Company");
      },
    },
    {
      id: "user",
      label: "User",
      icon: "ri-user-line",
      link: "/user",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("User");
      },
    },
    {
      id: "employee",
      label: "Employee",
      icon: "ri-team-line",
      link: "/employee",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Employee");
      },
    },
    {
      id: "company-types",
      label: "Company Types",
      icon: "ri-file-list-line",
      link: "/company-types",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Company-Types");
      },
    }
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
