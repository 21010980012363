import React, { useEffect, useState } from "react";
import generator from "generate-password-browser";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  Spinner,
  Label,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MetaTags from "react-meta-tags";
import { parsePhoneNumber } from "react-phone-number-input";
import { DateTime } from "luxon";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
//i18n
import { withTranslation } from "react-i18next";

import { UserTable } from "./GridTablesData";

import CustomSelect from "../../Components/Common/CustomSelect";
import { isActiveOptions, countryOptions } from "../../data/selectOptions";

import FormGroupText from "../../Components/Common/FormGroupText";

import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useAllCompanies, useUpdateCompany } from "../../hooks/useCompany";
import {
  useCreateUser,
  useFindUser,
  useUpdateUser,
} from "../../hooks/useUsers";
import "./phone.css";

const User = (props) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [phoneValue, setPhoneValue] = useState();
  const [regeneratePassword, setRegeneratePassword] = useState(false);
  const [companyArr, setCompanyArr] = useState([]);
  const { data: companyData } = useAllCompanies();
  const { data: userData } = useFindUser(selectedUser);
  const { mutate: editCompany } = useUpdateCompany();
  const {
    mutate: createUser,
    isSuccess: isSuccessUser,
    isLoading: isAddingUser,
  } = useCreateUser();
  const {
    mutate: updateUser,
    isSuccess: isSuccessEditUser,
    isLoading: isEditUser,
  } = useUpdateUser();

  useEffect(() => {
    companyData?.map((company) => {
      company.is_active === "aktiv" &&
        !company.company_user &&
        setCompanyArr((companyArr) => [
          ...companyArr,
          company && {
            value: company._id,
            label: company.company_name,
          },
        ]);
    });
  }, [companyData]);

  const [modal_standard, setmodal_standard] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
  }

  const handleEditEmployee = (id, data) => {
    updateUser({ id, data });
    setSelectedUser(null);
  };

  const handleOpenModal = (id) => {
    setSelectedUser(id);
    tog_standard();
  };

  useEffect(() => {
    if (userData?.phone) {
      setPhoneValue(userData.phone);
    } else {
      setPhoneValue("");
    }
  }, [userData]);

  useEffect(() => {
    if (isSuccessUser && !isAddingUser) {
      setmodal_standard(!modal_standard);
      validationUser.resetForm();
    }
    if (isSuccessUser && !isAddingUser) {
      toast(props.t("Successfully added User!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-success text-white",
      });
    }
  }, [isSuccessUser, isAddingUser]);

  useEffect(() => {
    if (phoneValue) {
      validationUser.setFieldValue("phone", phoneValue);
    } else {
      validationUser.setFieldValue("phone", "");
    }
  }, [phoneValue]);

  useEffect(() => {
    if (isSuccessEditUser && !isEditUser) {
      setmodal_standard(!modal_standard);
      validationUser.resetForm();
    }
    if (isSuccessEditUser && !isEditUser) {
      toast(props.t("Successfully edited User!"), {
        position: "top-right",
        hideProgressBar: true,
        closeOnClick: false,
        className: "bg-warning text-white",
      });
    }
  }, [isSuccessEditUser, isEditUser]);

  const validationUser = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      avatar: "",
      fname: userData?.fname || "",
      lname: userData?.lname || "",
      birthDate: userData?.birthDate
        ? DateTime.fromMillis(Number(userData?.birthDate)).toFormat(
            "yyyy-LL-dd"
          )
        : "",
      startingDate: userData?.startingDate
        ? DateTime.fromMillis(userData?.startingDate).toFormat("yyyy-LL-dd")
        : "",
      expiryDate: userData?.expiryDate
        ? DateTime.fromMillis(userData?.expiryDate).toFormat("yyyy-LL-dd")
        : "",
      // employeeid: userData?.employee_id || "",
      companyId: userData?.companyId || "",
      phone: userData?.phone || "",
      email: userData?.email || "",
      password: userData?.password || "",
      address: userData?.address || "",
      addressNumber: userData?.address_no || "",
      city: userData?.city || "",
      zip: userData?.zip || "",
      is_active: userData?.is_active || "",
      gender: userData?.gender || "",
      department: userData?.departments || [],
      service: userData?.services || [],
      isactive: userData?.is_active || "aktiv",
      country: "ch",
      biography: userData?.biography || "",
    },

    validationSchema: Yup.object({
      // avatar: Yup.string().required(
      // props.t('Please Enter Profile Image'),
      // ),
      companyId: Yup.string().required(props.t("Company")),
      gender: Yup.string().required(props.t("You must select gender")),
      fname: Yup.string().required(props.t("Please Enter First Name")),
      lname: Yup.string().required(props.t("Please Enter Last Name")),
      startingDate: Yup.string().required(
        props.t("Please Enter Starting Date")
      ),
      expiryDate: Yup.string().required(props.t("Please Enter Expiry Date")),
      // employeeid: Yup.string().required(props.t("Please Enter Employee ID")),
      // phone: Yup.string().required(props.t('Please Enter Phone')),
      email: Yup.string().email().required(props.t("Please Enter Email")),
      birthDate: Yup.string().required(props.t("Please Enter Birthdate")),
      phone: Yup.string().required(props.t("Please Enter Phone")),
      password:
        !selectedUser &&
        Yup.string()
          .required(props.t("Please Enter Password"))
          .matches(
            /^(?=.*[A-Z])(?=.*[0-9!@#\$%\^\&*\)\(+=._-]).{8,}$/,
            props.t("Password Validation")
          ),
      address: Yup.string().required(props.t("Please Enter Address")),
      city: Yup.string().required(props.t("Please Enter Your City")),
      zip: Yup.number().required(props.t("Please Enter Your Zip")),
      isactive: Yup.string().oneOf(
        isActiveOptions.map((item) => item.value),
        props.t("You must select at least one of the options") +
          isActiveOptions.map((item) => item.label)
      ),
      country: Yup.string().oneOf(
        countryOptions.map((item) => item.value),
        props.t("You must select at least one of the options") +
          countryOptions.map((item) => item.label)
      ),
      biography: Yup.string().required(
        props.t("Please enter Biography / Specialist")
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      const selectedCompany = companyData.find(
        (company) => company._id === values.companyId
      );
      const data = {
        gender: values.gender,
        fname: values.fname,
        lname: values.lname,
        birthDate: DateTime.fromFormat(
          values.birthDate,
          "yyyy-LL-dd"
        ).toMillis(),
        startingDate: DateTime.fromFormat(
          values.startingDate,
          "yyyy-LL-dd"
        ).toMillis(),
        expiryDate: DateTime.fromFormat(
          values.expiryDate,
          "yyyy-LL-dd"
        ).toMillis(),
        // employee_id: values.employeeid,
        phone: values.phone,
        email: values.email,
        address: values.address,
        address_no: values.addressNumber,
        zip: values.zip,
        city: values.city,
        password: values.password,
        biography: values.biography,
        roles: selectedCompany?.["is_super_company"]
          ? ["user", "employee", "admin", "moderator", "superadmin"]
          : ["user", "employee", "admin", "moderator"],
        userType: "employee",
        is_active: values.isactive,
        companyId: values.companyId,
      };
      if (selectedUser) {
        handleEditEmployee(selectedUser, {
          gender: values.gender,
          fname: values.fname,
          lname: values.lname,
          birthDate: DateTime.fromFormat(
            values.birthDate,
            "yyyy-LL-dd"
          ).toMillis(),
          startingDate: DateTime.fromFormat(
            values.startingDate,
            "yyyy-LL-dd"
          ).toMillis(),
          expiryDate: DateTime.fromFormat(
            values.expiryDate,
            "yyyy-LL-dd"
          ).toMillis(),
          // employee_id: values.employeeid,
          phone: values.phone,
          email: values.email,
          address: values.address,
          address_no: values.addressNumber,
          zip: values.zip,
          city: values.city,
          biography: values.biography,
          userType: "employee",
          is_active: values.isactive,
          companyId: values.companyId,
        });
      } else {
        createUser(data);
      }
    },
  });

  useEffect(() => {
    const password = generator.generate({
      length: 10,
      uppercase: true,
      numbers: true,
    });
    validationUser.setFieldValue("password", password);
  }, [regeneratePassword]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <MetaTags>
            <title>
              {props.t("User")} | {props.t("MyHealth Brend")}
            </title>
          </MetaTags>
          <BreadCrumb title={props.t("User")} pageTitle={props.t("User")} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="align-items-center d-flex border-bottom border-3 border-primary">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {props.t("User")}
                  </h4>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <Button
                        className="btn btn-danger"
                        onClick={() => tog_standard()}
                      >
                        <i className="ri-add-fill label-icon align-middle me-2"></i>
                        {props.t("Create User")}
                      </Button>
                    </div>
                  </div>
                </CardHeader>

                <CardBody>
                  <div id="table-gridjs">
                    <UserTable
                      handleOpenModal={handleOpenModal}
                      firstname={props.t("First name")}
                      lastname={props.t("Last name")}
                      phone={props.t("Phone")}
                      actionDoots={props.t("Action doots")}
                      edit={props.t("Edit")}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* User Modal */}
      <Modal
        className="modal-lg"
        id="myModal"
        onClosed={() => {
          setSelectedUser(null);
          validationUser.setTouched({}, false);
        }}
        isOpen={modal_standard}
        toggle={() => {
          ((!isAddingUser && selectedUser === null) ||
            (!isEditUser && selectedUser !== null)) &&
            tog_standard();
        }}
      >
        <ModalHeader
          className="p-3 bg-primary"
          toggle={() => {
            ((!isAddingUser && selectedUser === null) ||
              (!isEditUser && selectedUser !== null)) &&
              tog_standard();
          }}
        >
          <span className="text-white">{props.t("Employee save")}</span>
        </ModalHeader>
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            validationUser.handleSubmit();
            return false;
          }}
        >
          <div className="modal-body">
            {selectedUser !== null && userData === undefined && (
              <Row>
                <Col lg="12">
                  <Spinner />
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <label className="form-label">
                    {props.t("Gender")} <span className="text-danger">*</span>
                  </label>
                  <div>
                    <div className="form-check form-check-inline">
                      <Input
                        disabled={
                          isAddingUser ||
                          isEditUser ||
                          (selectedUser !== null && userData === undefined)
                        }
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="male"
                        checked={userData && userData.gender === "male" && true}
                        onChange={(e) =>
                          validationUser.setFieldValue("gender", e.target.value)
                        }
                        value="male"
                      />
                      <label className="form-check-label" htmlFor="male">
                        {props.t("Male")}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Input
                        disabled={
                          isAddingUser ||
                          isEditUser ||
                          (selectedUser !== null && userData === undefined)
                        }
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        checked={
                          userData && userData?.gender === "male" && true
                        }
                        id="female"
                        onChange={(e) =>
                          validationUser.setFieldValue("gender", e.target.value)
                        }
                        value="female"
                      />
                      <label className="form-check-label" htmlFor="female">
                        {props.t("Female")}
                      </label>
                    </div>
                  </div>
                  {validationUser.touched.gender &&
                    validationUser.errors.gender && (
                      <p
                        style={{
                          color: "#f06548",
                          marginTop: "0.25rem",
                          fontSize: "0.875em",
                        }}
                      >
                        {validationUser.errors.gender}
                      </p>
                    )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <CustomSelect
                  isDisabled={isAddingUser || selectedUser}
                  label={props.t("Company")}
                  isRequired={true}
                  onChange={(value) =>
                    validationUser.setFieldValue("companyId", value.value)
                  }
                  selectOptions={companyArr}
                  selectValues={validationUser.values.companyId}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroupText
                  disabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  idInput="fname"
                  label={props.t("First name")}
                  isRequired={true}
                  attributeName="fname"
                  placeholder=""
                  validation={validationUser}
                  type="text"
                />
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <FormGroupText
                  disabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  idInput="lname"
                  label={props.t("Last name")}
                  isRequired={true}
                  attributeName="lname"
                  placeholder=""
                  validation={validationUser}
                  type="text"
                />
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <FormGroupText
                  disabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  idInput="birthDate"
                  label={props.t("Birthday")}
                  isRequired={true}
                  attributeName="birthDate"
                  placeholder=""
                  validation={validationUser}
                  type="date"
                />
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroupText
                  disabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  idInput="startingDate"
                  label={props.t("Starting date")}
                  isRequired={true}
                  attributeName="startingDate"
                  placeholder=""
                  validation={validationUser}
                  max={validationUser.values.expiryDate}
                  type="date"
                />
              </Col>
              <Col lg="6">
                <FormGroupText
                  disabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  idInput="expiryDate"
                  label={props.t("Expiry date")}
                  isRequired={true}
                  attributeName="expiryDate"
                  placeholder=""
                  min={
                    DateTime.now().toFormat("yyyy-LL-dd") &&
                    validationUser.values.startingDate
                  }
                  validation={validationUser}
                  type="date"
                />
              </Col>
            </Row>

            {/* <Row>
              <Col lg="12">
                <FormGroupText
                  disabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  idInput="employeeid"
                  label={props.t("Employee ID")}
                  isRequired={true}
                  attributeName="employeeid"
                  placeholder=""
                  validation={validationUser}
                  type="text"
                />
              </Col>
            </Row> */}

            <Row>
              <Col lg="12">
                <div style={{ marginBottom: "1rem" }}>
                  <Label htmlFor="phone">
                    {"Phone"} {<span className="text-danger">*</span>}
                  </Label>
                  <PhoneInput
                    style={{ border: "1px solid #ced4da" }}
                    numberInputProps={{
                      className: "phone-input",
                    }}
                    name="phone"
                    id="phone"
                    placeholder="Enter phone number"
                    defaultCountry="CH"
                    value={phoneValue}
                    onChange={setPhoneValue}
                  />
                  {validationUser.touched.phone &&
                    validationUser.errors.phone && (
                      <p
                        style={{
                          color: "#f06548",
                          marginTop: "0.25rem",
                          fontSize: "0.875em",
                        }}
                      >
                        {validationUser.errors.phone}
                      </p>
                    )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <FormGroupText
                  disabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  idInput="email"
                  label={props.t("Email")}
                  isRequired={true}
                  attributeName="email"
                  placeholder=""
                  validation={validationUser}
                  type="email"
                />
              </Col>
            </Row>

            {!selectedUser && (
              <Row>
                <Col lg="12">
                  <FormGroupText
                    disabled={
                      isAddingUser ||
                      isEditUser ||
                      (selectedUser !== null && userData === undefined)
                    }
                    onRegenerateClick={() =>
                      setRegeneratePassword((prev) => !prev)
                    }
                    idInput="password"
                    label={props.t("Password")}
                    isRequired={true}
                    attributeName="password"
                    placeholder=""
                    validation={validationUser}
                    type="password"
                  />
                </Col>
              </Row>
            )}

            <hr></hr>

            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label htmlFor="biography">
                    {props.t("Biography Specialist")}{" "}
                    <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    disabled={
                      isAddingUser ||
                      isEditUser ||
                      (selectedUser !== null && userData === undefined)
                    }
                    name="biography"
                    placeholder=""
                    type="text"
                    className="form-control"
                    id="biography"
                    rows={3}
                    onChange={validationUser.handleChange}
                    onBlur={validationUser.handleBlur}
                    value={validationUser.values.biography || ""}
                    invalid={
                      validationUser.touched.biography &&
                      validationUser.errors.biography
                        ? true
                        : false
                    }
                    style={
                      validationUser.touched.biography &&
                      validationUser.errors.biography && {
                        borderColor: "#e74c3c",
                      }
                    }
                  />
                  {validationUser.touched.biography &&
                    validationUser.errors.biography && (
                      <p
                        style={{
                          color: "#f06548",
                          marginTop: "0.25rem",
                          fontSize: "0.875em",
                        }}
                      >
                        {validationUser.errors.biography}
                      </p>
                    )}
                </FormGroup>
              </Col>
            </Row>

            <hr></hr>

            <Row>
              <Col lg="8">
                <FormGroupText
                  disabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  idInput="address"
                  label={props.t("Address")}
                  isRequired={true}
                  attributeName="address"
                  placeholder=""
                  validation={validationUser}
                  type="text"
                />
              </Col>
              <Col lg="4">
                <FormGroupText
                  disabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  idInput="addressNumber"
                  label={props.t("AddressNo")}
                  attributeName="addressNumber"
                  placeholder=""
                  validation={validationUser}
                  type="text"
                />
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <FormGroupText
                  disabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  idInput="zip"
                  label={props.t("Zip")}
                  isRequired={true}
                  attributeName="zip"
                  placeholder=""
                  validation={validationUser}
                  type="text"
                />
              </Col>
              <Col md="4">
                <FormGroupText
                  disabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  idInput="city"
                  label={props.t("City")}
                  isRequired={true}
                  attributeName="city"
                  placeholder=""
                  validation={validationUser}
                  type="text"
                />
              </Col>
              <Col md="4">
                <CustomSelect
                  isDisabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  label={props.t("Country")}
                  onChange={(value) =>
                    validationUser.setFieldValue("country", value.value)
                  }
                  selectOptions={countryOptions}
                  selectValues={validationUser.values.country}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <CustomSelect
                  isDisabled={
                    isAddingUser ||
                    isEditUser ||
                    (selectedUser !== null && userData === undefined)
                  }
                  label={props.t("Status")}
                  onChange={(value) =>
                    validationUser.setFieldValue("isactive", value.value)
                  }
                  selectOptions={isActiveOptions}
                  selectValues={validationUser.values.isactive}
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            {(!isAddingUser && selectedUser === null) ||
            (!isEditUser && selectedUser !== null) ? (
              <>
                <Button
                  color="light"
                  onClick={() => {
                    tog_standard();
                    setSelectedUser(null);
                  }}
                >
                  {props.t("Close")}
                </Button>
                <Button color="primary" type="submit">
                  {props.t("Submit")}
                </Button>
              </>
            ) : (
              <Button disabled color="primary" className="btn-load">
                <span className="d-flex align-items-center">
                  <span className="flex-grow-1 me-2">{props.t("Loading")}</span>
                  <Spinner size="sm" className="flex-shrink-0" role="status" />
                </span>
              </Button>
            )}
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(User);
